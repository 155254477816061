<template>
<div class="table-panel">
  <Table :columns="dataColumns" :data="dataList"></Table>
  <div>
    <span class="records">共 {{ dataTotal }} 条记录</span>
  </div>
  <div class="page-nations-wrapper">
    <div class="page-nations">
      <Page
        :total="dataTotal"
        :current="dataCurrentPage"
        :page-size="cardQuery.size"
        @on-change="changeDataPage"
      ></Page>
    </div>
  </div>
</div>
</template>

<script type="text/ecmascript-6">
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			cardQuery: {
				page: 1,
				size: 20,
				member_id: '',
			},
			dataColumns: [
				{ title: '日期', key: 'rq', align: 'center' },
				{ title: '姓名', key: 'xm', align: 'center' },
				{ title: '内容', key: 'nr', align: 'center' },
				{ title: '服务员工', key: 'fwyg', align: 'center' },
			],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
		};
	},
	created() {
		this.getCardRecords();
	},
	methods: {
		getCardRecords() {
			this.cardQuery.member_id = this.$route.params.member_id;
			cardService.getCardServices(this.cardQuery).then((data) => {
				this.dataTotal = data.row_size;
				this.dataCurrentPage = data.cur_page;
				this.dataList = data.list;
			});
		},
		changeDataPage(page) {
			this.cardQuery.page = page;
			this.getCardRecords();
		},
	},
};
</script>